import React from "react";

const Weather = props => {

  const {city, country, temp, presure, sunset, error} = props;

  return (
    <div className="infoWeath">
      {
        city ? (
            <div>
              <p>
                Местоположение: {city}, {country}
              </p>
              <p>Температура: {temp} </p>
              <p>Давление: {presure} </p>
              <p>Заход солнца: {sunset} </p>
            </div>
          ) :
          <p className="error">{error}</p>
      }
    </div>
  );
};

export default Weather;
