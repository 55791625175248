import React from "react";

const Form = (props) => {
  const { weatherMethod } = props;

  return (
    <form onSubmit={weatherMethod}>
      <input type="text" name="city" placeholder="Местоположение"/>
      <button>Получить</button>
    </form>
  )
};

// const Form = props => (
//   <form onSubmit={props.weatherMethod}>
//     <input type="text" name="city" placeholder="Местоположение"/>
//     <button>Получить</button>
//   </form>
// );

export default Form;
