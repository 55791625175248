import React from 'react';
import Info from './components/Info';
import Form from './components/form';
import Weather from './components/weather';

const API_KEY = '5aada94b3a34de9a58f4be7766c9ae02';

class App extends React.Component {

    componentDidMount() {

      this.getWeatherR()
    }

  state = {
        temp: null,
        city: null,
        country: null,
        presure: null,
        sunset: null,
        error: null
    }

    getWeatherR = async (city) => {
      if (city) {

        const api_url = await
        fetch(`http://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${API_KEY}&units=metric`);
        const data = await api_url.json();

        if(!data.sys || !data.sys.sunset) {

          this.setState({error: 'Такого города нет'})
          return null;
        }

        const sunset = data.sys.sunset;
        const date = new Date();
        date.setTime(sunset * 1000);
        const sunset_date = date.getHours() + ':' + date.getMinutes();

        this.setState({
          temp: data.main.temp,
          city: data.name,
          country: data.sys.country,
          presure: data.main.pressure,
          sunset: sunset_date,
          error: null
        });
      } else {

        this.setState({
          temp: null,
          city: null,
          country: null,
          presure: null,
          sunset: null,
          error: "Enter the city"
        })
      }
    }


    gettingWeather = async (e) => {

        e.preventDefault();
        const city = e.target.elements.city.value;

        this.getWeatherR(city);
    }

    render() {
        return (
            <div className = "wrapper">
            <div className="main">
                <div className = "container">
                    <div className = "row">
                        <div className="col-sm-5 info">
                            <Info />
                        </div>
                        <div className = "col-sm-7 form">
                            <Form weatherMethod = {this.gettingWeather} />
                            <Weather
                                temp = {this.state.temp}
                                city = {this.state.city}
                                country = {this.state.country}
                                presure = {this.state.presure}
                                sunset = {this.state.sunset}
                                error = {this.state.error}
                        />
                    </div>

                </div>
            </div>
            </div>



        < /div>
    )
        ;
    }
}

export default App;
