import React from "react"

const Info = () => {
  return (
    <div>
      <h2>Wheather application</h2>
      <p>Узнать погоду в вашей локации</p>
    </div>
  )
}

export default Info
